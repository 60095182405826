<nb-card class="copilot-card">
  <nb-card-header class="header">
    <div>
      {{title}}
    </div>
    <!-- <nb-icon class="help-icon" aria-label="Close" tabindex="0" role="button" icon="close-outline" status="danger"
      (click)="dismiss()" id="help-modal-content-close-outline">
    </nb-icon> -->
  </nb-card-header>
  <nb-card-body>
    <div class="text-left" *ngIf="questions">
      <ul style="padding-left: 3em;">
        <li  style="margin-bottom: .3em;" *ngFor="let question of questions">
          {{ question }}
        </li>
      </ul>
    </div>
  </nb-card-body>
</nb-card>
