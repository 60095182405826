import { Component, HostListener } from '@angular/core';
import { StateService } from '@cortex/utilities';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {
  mobileMode = false;
  sidebar = false;
  screenWidth: any;
  screenHeight: any;
  userName: string;
  homeLink:string;
  configs = window.cortexCore.config.config;

  constructor(private stateService: StateService) {
    this.userName = window.cortexCore.auth.userProfile.name;
    this.checkHomeIcon();
  }

  checkHomeIcon() {
    const homeIconConfigs = this.configs.features?.header?.homeIcon;
    const username = window.cortexCore.auth.userProfile.preferred_username;

    if (homeIconConfigs && homeIconConfigs['enabled'] && homeIconConfigs['allowedAgents'].includes(username)) {
      this.homeLink = homeIconConfigs.link;
    }
  }

  logout(): void {
    window.cortexCore.auth.logout();
  }

  changeTheme(theme: string): void {
    this.stateService.setKeyValue('theme', theme);
  }

  changeLanguage(lang: string): void {
    this.stateService.setKeyValue('language', lang);
  }

  @HostListener('window:resize', ['$event'])
  getScreenSize(): void {
    this.screenHeight = window.innerHeight;
    this.screenWidth = window.innerWidth;
    if (this.screenWidth <= 600) {
      this.mobileMode = true;
    } else {
      this.mobileMode = false;
    }
  }
}
