import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnDestroy, ViewChild } from '@angular/core';
import { NavigationEnd, Router, Routes } from '@angular/router';
import { AssetUrlPipe, StateService } from '@cortex/utilities';
import { combineLatest, Subject } from 'rxjs';
import { first, takeUntil } from 'rxjs/operators';
import { ERROR_ROUTES, HOME_ROUTES } from './app-routing.module';
import { EmptyComponent } from './empty/empty.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements AfterViewInit, OnDestroy {
  @ViewChild('appContainer', { static: false }) container: ElementRef;
  links: { route: string; name: string }[] = [];
  dialer = false;
  unsubscribeAll$!: Subject<void>;
  loadedScripts = [];
  prevUrl = "";

  private readonly config;
  private readonly routes: Routes = [];
  private loadedAppElements: HTMLElement[] = [];
  private selectedTheme = 'light';
  private selectedLanguage = 'en';
  private userProfile;
  userData: any;

  private sessionExpired$: Subject<void>;
  sessionExpired: boolean = false;

  constructor(public router: Router, private stateService: StateService, private cdr: ChangeDetectorRef,
    private assetUrlPipe: AssetUrlPipe,) {
    this.unsubscribeAll$ = new Subject();
    this.config = window.cortexCore.config.getConfig();
    this.userProfile = window.cortexCore.auth.userProfile;

    // SUBSCRIBING TO THE SESSION EXPIRED SUBJECT
    this.sessionExpired$ = window.cortexCore?.auth?.onSessionExpired();
    this.sessionExpired$.pipe(first()).subscribe(_ => {
      this.sessionExpired = true;
      this.cdr.detectChanges();
    });



    const telemetryConfig = {
      ...this.config?.telemetryConfig,
      eventOptions: {
        ...this.config?.telemetryConfig?.eventOptions,
        context: {
          ...this.config?.telemetryConfig?.eventOptions?.context,
          env: 'Agent Dekstop',
          pdata: {
            id: 'AgentDekstopComponent',
            ver: '2.0'
          }
        },
        actor: {
          ...this.config?.telemetryConfig.eventOptions?.actor,
          id: this.userProfile?.preferred_username
        }
      }
    };
    window.cortexCore?.telemetry.initialize(telemetryConfig);
    window.cortexCore?.telemetry.start({ type: 'start' });

    combineLatest([this.stateService.getValueOf('theme'), this.stateService.getValueOf('language')])
      .pipe(takeUntil(this.unsubscribeAll$))
      .subscribe(([selectedTheme, selectedLanguage]) => {
        if (selectedTheme) {
          this.selectedTheme = selectedTheme;
          this.applySelectedAttribute('theme', selectedTheme);
        }
        if (selectedLanguage) {
          this.selectedLanguage = selectedLanguage;
          this.applySelectedAttribute('language', selectedLanguage);
        }
      });
  }

  logout = (): void => {
    window.cortexCore.auth.logout();
  };

  ngAfterViewInit(): void {
    //Storing BaseUrl and will redirect to the root url entered
    const baseUrl: String = window.sessionStorage.getItem('BaseUrl');
    window.sessionStorage.removeItem('BaseUrl');
    /*Checking if initial route is not homePage route then it navigates to following route after login*/
    if (baseUrl.indexOf('#') > -1) {
      const afterBaseUrl = baseUrl.substring(baseUrl.indexOf('#') + 1);
      this.router.navigate([afterBaseUrl]);
    }
    this.userData = this.userProfile;
    const microApps = this.config['micro-apps'];
    let microApp = [];
    try {
      for (const key in microApps) {
        const microAppAccess = this.userData.canAccess
          .map(val => {
            if (val === microApps[key].group || microApps[key].group === 'Other') {
              if (
                key === 'app-dashboard' &&
                !this.userData.roles.includes('ROLE_SUPERVISOR') &&
                !this.userData.roles.includes('ROLE_ADMIN')
              )
                microApps[key].landing.url = '#/dashboard/agent-dashboard';
              return microApps[key];
            }
          })
          .filter(app => app !== undefined);
        microApp = [...microApp, ...microAppAccess];
      }
    } catch (error) {
      throw new Error(`User doesn't have access to any module. Please contact administrator for access`);
    }
    const menuItems = microApp.filter(app => app.landing).map(app => app.landing);
    this.stateService.setKeyValue('microApp', menuItems);
    const appNames = microApp;
    if (appNames.length) {
      if (this.container) {
        this.loadedAppElements = [];
        appNames.forEach(appName => {
          this.loadValidRoutes(appName.name, appName);
        });
        this.subscribeToRoutingEvents();
        this.router.resetConfig([
          ...HOME_ROUTES,
          ...this.routes,
          ...ERROR_ROUTES
        ]);
      } else {
        console.error("No DOM element available to load apps.");
      }
    }
    this.insertuiAdapters()
  }

  ngOnDestroy(): void {
    this.unsubscribeAll$.next();
    this.unsubscribeAll$.complete();
  }

  openDialer(event: boolean): void {
    this.dialer = event;
    (document.querySelector('agent-desktop') as any).dialerOpen = this.dialer;
  }

  private applySelectedAttribute(attributeName, value) {
    this.loadedAppElements.forEach(element => element.setAttribute(attributeName, value));
  }


  getMicroAppForRoute(route, microApps) {
    for (const key in microApps) {
      if (Object.prototype.hasOwnProperty.call(microApps, key)) {
        const microApp = microApps[key];
        if (microApp.route.indexOf(route) !== -1) {
          return microApp;
        }
      }
    }
    return {};
  }

  loadScriptWithPriority(priorityScripts,nonPriorityScripts,hostname,index=0){
    if(index<priorityScripts.length){
      const scriptTag = document.createElement('script');
      if (priorityScripts[index].includes('http')) {
        scriptTag.setAttribute('src', `${priorityScripts[index]}`);
      } else {
        scriptTag.setAttribute('src', `${hostname}${priorityScripts[index]}`);
      }
      scriptTag.setAttribute('defer', '');
      scriptTag.onload=()=>
        this.loadScriptWithPriority(priorityScripts,nonPriorityScripts,hostname,index+1);
        this.container.nativeElement.appendChild(scriptTag);
    }
    else{
      nonPriorityScripts?.forEach(path => {
        const scriptTag = document.createElement('script');
        if (path.includes('http')) {
          scriptTag.setAttribute('src', `${path}`);
        } else {
          scriptTag.setAttribute('src', `${hostname}${path}`);
        }
        scriptTag.setAttribute('defer', '');
        this.container.nativeElement.appendChild(scriptTag);
      });
    }
  }

  subscribeToRoutingEvents() {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        if ( event.url != "/" && this.prevUrl != event.url) {
          this.prevUrl = event.url;
          const url = event.url;
          const startIndex = url.indexOf("/");
          const endIndex = url.indexOf("/", startIndex + 1);
          let activeMicroAppName;
          if (startIndex !== -1 && endIndex !== -1) {
            activeMicroAppName = url.substring(startIndex + 1, endIndex);
          } else if (startIndex !== -1 && endIndex == -1) {
            activeMicroAppName = url.substring(startIndex + 1);
          }
          this.loadMicroApp(activeMicroAppName, this.config["micro-apps"]);
        }
      }
    });
  }

  insertuiAdapters(): void {
    const scriptTag = document.createElement('script');
    scriptTag.setAttribute('src', this.assetUrlPipe.transform("/assets/js/ui-adaptors.js"));
    scriptTag.setAttribute('defer', '');
    const domelem = document.querySelector('body');
    domelem.insertBefore(scriptTag, domelem.firstChild);
  }

  private loadMicroApp(ele: string, microApps): void {
    const config = this.getMicroAppForRoute(ele, microApps);
    const { hostname = '', assetHost, scripts, styles, loaded, route, prioritiseJS=false, name, element = name, priorityScripts, nonPriorityScripts } = config;
    if (loaded) {
      return;
    }
    if(!prioritiseJS){
      scripts?.forEach(path => {
      const scriptTag = document.createElement('script');
      if (path.includes('http')) {
        scriptTag.setAttribute('src', `${path}`);
      } else {
        scriptTag.setAttribute('src', `${hostname}${path}`);
      }
      scriptTag.setAttribute('defer', '');
      this.container.nativeElement.appendChild(scriptTag);
      });
    }
    else{
      this.loadScriptWithPriority(priorityScripts,nonPriorityScripts,hostname);
    }

    styles?.forEach(style => {
      const styleTag = document.createElement('link');
      styleTag.setAttribute('href', `${hostname}${style}`);
      styleTag.setAttribute('rel', 'stylesheet');
      document.head.appendChild(styleTag);
    });

    if (scripts?.length) {
      const domElem = document.createElement(element);
      domElem.setAttribute('host', hostname);
      if (assetHost) domElem.setAttribute('assetHost', assetHost);
      if (element === 'agent-desktop') {
        domElem.setAttributeNS(null, 'dialerOpen', this.dialer);
      }
      domElem.setAttribute('language', this.selectedLanguage);
      domElem.setAttribute('theme', this.selectedTheme);

      this.loadedAppElements.push(domElem);
      this.container.nativeElement.appendChild(domElem);
      config.loaded = true;
    }
  }

  private loadValidRoutes(ele: string, config): void {
    const {  loaded, route, name, element = ele } = config;
    if (loaded) {
      return;
    }
    this.links.push({ route: `#/${route}`, name });
    (route as string[]).forEach((routeElement: any) => {
      this.routes.push({ path: `${routeElement}`, component: EmptyComponent });
    });
  }
}
