<div class="container-fluid demo-videos">
    <div class="row">
        <div class="col-12 mb-4 mt-4">
            <a href="#/" id="applications-link" class="prev-screen-link">Home</a>
            <nb-icon class="mx-2" icon="chevron-right-outline" aria-hidden="true"></nb-icon>
            <span class="curr-screen-text">Demo-Videos</span>
        </div>
    </div>
    <div class="row">
        <div class="col-12 row mb-2" *ngIf="!videoLink">
            <div class="col-md-3 col-sm-6 mt-2" *ngFor="let video of videos; let i = index">
                <crtx-showcase-card [model]='video' (click)="playVideo(i)"></crtx-showcase-card>
            </div>
        </div>
        <div class="col-12 video-container p-0" *ngIf="videoLink">
          <div class="col-12 main-heading d-flex justify-content-between">
                <h4>{{videoHeading}}</h4>
            </div>
          <video [src]="videoLink" controls style="width: 100%; height: 38rem; object-fit: contain;" autoplay></video>
          <nb-icon
            status="danger"
            role="button"
            icon="close-outline"
            class="cross-icon"
            (click)="close()"
          ></nb-icon>
        </div>
    </div>
</div>
