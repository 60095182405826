import { Component, Input } from '@angular/core';
// import { NbDialogRef, NbDialogService } from '@nebular/theme';

@Component({
  selector: "app-help-modal",
  templateUrl: "./help-modal.component.html",
  styleUrls: ["./help-modal.component.scss"]
})
export class HelpModalComponent {

  title = "Try these with Copilot";
  questions = [];

  constructor() {
    let questions = sessionStorage.getItem('questions')
    if (questions) {
      this.questions = JSON.parse(questions);
    }
  }


}
