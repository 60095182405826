import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {
  selectedChannel = 'twilio';
  selectedDomain = 'telecom';

  showDomainSelection = true;

  constructor(private router: Router) {
    const config = window.cortexCore.config.getConfig();
    const userId = window.cortexCore.auth.userProfile.preferred_username;
    const domain = window.cortexCore.auth.userProfile.domain;
    this.setCurrentChannelDomain(router.url);

    if (!!domain) {
      this.changeDomain('');
      this.showDomainSelection = false;
    }
  }

  checkCurrentLink(str: string): boolean {
    return this.router.url.includes(str);
  }

  changeChannel(data: string): void {
    this.selectedChannel = data;
  }

  setCurrentChannelDomain(url: string): void {
    this.selectedChannel = url.split('/')[2] ? url.split('/')[2] : this.selectedChannel;
    this.selectedDomain = url.split('/')[3] ? url.split('/')[3] : this.selectedDomain;
  }

  changeDomain(data: string): void {
    this.selectedDomain = data || '';
  }
}
