<nb-layout>
  <nb-layout-column>
    <div class="pageNotFound">
      <div>
        <svg-icon src="/assets/icons/404-Page-Not-Found.svg" [svgStyle]="{ 'width.rem': '16' }"></svg-icon>
      </div>
      <div>
        <h1 class="title">Whoops !</h1>
        <div class="text">
          <p>The page doesn't exist or the url entered is wrong.</p>
          <p>We suggest you go back home.</p>
        </div>

        <button nbButton shape="round" size="medium" status="primary" class="px-5 mt-3" [routerLink]="['/']">Home</button>
      </div>
    </div>
  </nb-layout-column>
</nb-layout>
