import { HttpClientModule } from "@angular/common/http";
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { AuthModule, ConfigModule, MicroAppModule } from "@cortex/utilities";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { NgbDropdownModule } from "@ng-bootstrap/ng-bootstrap";
import { AngularSvgIconModule } from "angular-svg-icon";
import { AssetUrlPipe } from "../utils/asset-url.pipe";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { IcortexCoreModule } from "./core/core.module";
import { EmptyComponent } from "./empty/empty.component";
import { FooterComponent } from "./footer/footer.component";
import { HeaderComponent } from "./header/header.component";
import { HomeComponent } from "./home/home.component";
import { SidebarComponent } from "./sidebar/sidebar.component";
import { PromptBoxComponent } from "./prompt-box/prompt-box.component";
import { PageNotFoundComponent } from "./page-not-found/page-not-found.component";
import { NbButtonModule, NbIconModule, NbLayoutModule } from "@nebular/theme";
import { CommonModule } from "@angular/common";
import { DemoVideosComponent } from './demo-videos/demo-videos.component';
import { CortexWidgetsModule } from "@cortex/widgets";
import { NbCardModule, NbDialogModule, NbDialogService } from "@nebular/theme";
import { HelpModalComponent } from './help-modal/help-modal.component';
import { NbEvaIconsModule } from "@nebular/eva-icons";

declare global {
  interface Window {
    cortexCore: any;
  }
}

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    HomeComponent,
    AssetUrlPipe,
    SidebarComponent,
    EmptyComponent,
    PageNotFoundComponent,
    PromptBoxComponent,
    DemoVideosComponent,
    HelpModalComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    IcortexCoreModule,
    NgbDropdownModule,
    ConfigModule.forApp(),
    AngularSvgIconModule.forRoot(),
    FontAwesomeModule,
    MicroAppModule,
    NbDialogModule.forRoot(),
    NbCardModule,
    NbIconModule,
    NbEvaIconsModule,
    AuthModule.forApp(),
    AngularSvgIconModule,
    NbLayoutModule,
    NbButtonModule,
    CommonModule,
    CortexWidgetsModule,
    NbIconModule,
    NbEvaIconsModule,
    AngularSvgIconModule
  ],
  providers: [NbDialogService],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule {}
