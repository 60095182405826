<div class="contatiner masthead">
  <div class="overlay">
    <div class="row justify-content-center justify-content-sm-end">
      <div class="overlay-text">
        <div class="heading d-flex px-5" role="banner">
          BETTER BRAIN POWER
          <div class="multiply pl-2">X</div>
          <div class="double">2</div>
        </div>
        <div class="sub-heading px-5">
          <span class="pr-2 pr-md-3">Recruit</span><img src="/assets/images/arrow-greater-than.svg" alt="arrow" class="image-width" /><span
            class="px-2 px-md-3"
            >Train</span
          >
          <img src="/assets/images/arrow-greater-than.svg" alt="arrow" class="image-width" /><span class="px-2 px-md-3">Operate</span>
          <img src="/assets/images/arrow-greater-than.svg" alt="arrow" class="image-width" /><span class="pl-2 pl-md-3">Evolve</span>
        </div>
      </div>
    </div>
    <div id="mastHeadHome" class="row row-height">
      <div>
        <img src="/assets/images/Cortex-Blue-new.svg" alt="cortex-logo" class="img-logo img-responsive demoVideoBtn" aria-label="Infosys Cortex" (click)="cortexButtonClicked()" [ngClass]="{'demoVideoBtn': enbaleDemoVideosBtn}"/>
      </div>
    </div>
    <div class="row pl-0 ml-0 pl-sm-5 ml-sm-5 row2-height row-items align-items-center">
      <div>
        <img src="/assets/images/PersonVector.svg" alt="" class="person-img" />
      </div>
      <div class="ml-3 mr-5">
        <div class="welcome-text">Welcome Back</div>
        <div class="name">
          <span class="pr-3">{{ name }}</span>
          <span
            class="sign-out pl-3 logout-seperation"
            aria-label="Sign out"
            tabindex="0"
            (keyup.enter)="logout()"
            id="logout"
            (click)="logout()"
          >
            <svg-icon src="/assets/logout-icon.svg" aria-hidden="true"></svg-icon>
          </span>
        </div>
      </div>
    </div>
    <div class="row pl-0 ml-0 pl-sm-5 ml-sm-5 row-items">
      <div *ngFor="let item of menuItems" class="card-style mr-4 ml-3 pb-1 text-center">
        <a [href]="item.url" (click)="getMenuItem(item.header)">
          <div class="main-text py-3">
            {{ item.header }}
          </div>
          <div class="img-padding">
            <svg-icon [src]="item.icon"></svg-icon>
            <div class="row justify-content-end">
              <img src="/assets/images/arrow-white.svg" class="arrow-white arrow-style" alt="arrow" />
              <img src="/assets/images/arrow-blue.svg" class="arrow-blue arrow-style" alt="arrow" />
            </div>
          </div>
          <div class="text-block">
            {{ item.hoverText }}
          </div>
        </a>
      </div>
      <div class="extra-items row p-0 justify-content-between">
        <div class="aipanelist-menu-item col-5" title="Copilot" >
          <a href="#/ai-expert-config" (click)="getMenuItem('Copilot')">
            <svg-icon class="copilot-icon" [src]="'assets/icons/logo.svg' | assetUrl"></svg-icon>
          </a>
        </div>
        <div class="copilot-menu-item col-5" title="Copilot" >
          <a href="#/copilot/industries" (click)="getMenuItem('Copilot')">
            <svg-icon class="copilot-icon" src="/assets/images/copilot.svg"></svg-icon>
          </a>
        </div>
      </div>
    </div>
    <div class="error pl-5 ml-5">{{ message }}</div>
  </div>
</div>
