import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserProfile, StateService } from '@cortex/utilities';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, OnDestroy {
  userProfile: UserProfile;
  name: string;
  message = '';
  menuItems = [];
  enbaleDemoVideosBtn:boolean = false;
  constructor(private stateService: StateService,
    private router: Router
  ) {
    this.userProfile = window.cortexCore.auth.userProfile;
    const enbaleDemoVideos = window.cortexCore.config.config?.features?.demoVideos?.enabled;
    const allDemoVideosAllowedUsers = window.cortexCore.config.config?.features?.demoVideos?.allowedAgents;
    const username = window.cortexCore.auth.userProfile.preferred_username;
    this.enbaleDemoVideosBtn = enbaleDemoVideos && allDemoVideosAllowedUsers && allDemoVideosAllowedUsers.includes(username);
  }
  ngOnDestroy(): void {
    localStorage.clear();
  }

  cortexButtonClicked(){
    if(this.enbaleDemoVideosBtn){
      this.router.navigate(["demo-videos"]);
    }
  }

  ngOnInit(): void {
    this.name = this.userProfile.name;
    this.stateService.getValueOf('microApp').subscribe(val => {
      val ? (this.menuItems = val) : (this.message = "User doesn't have access to any module. Please contact administrator for access");
    });
  }
  getMenuItem(group: string): void {
    let groupArray;
    if (group.toLowerCase() !== 'copilot') {
      groupArray = ['Other', group];
    }
    //groupArray.push(group);
    console.log('===>>>', groupArray);
    this.stateService.setKeyValue('getGroup', groupArray);
    sessionStorage.removeItem('questions');
  }
  logout(): void {
    window.cortexCore.auth.logout();
  }
}
