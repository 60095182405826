import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { IMPRESSIONEVENTS } from '@cortex/telemetry';
import { StateService } from '@cortex/utilities';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit, OnDestroy {
  @Input() dialer = false;
  @Output() dialerEvent = new EventEmitter();
  group = [];
  cortexLearnLinks = ['track-progress', 'learn/courses', 'training-bank', 'conversational-analytics', 'trainer-dashboard'];
  currentRoute;
  unsubscribeAll$: Subject<void>;
  softPhoneMode:string;
  displayDialog: Boolean = false;
  private readonly config;
  constructor(public router: Router, private stateService: StateService) {
    this.softPhoneMode = window.cortexCore?.auth?.userProfile?.ctiProvider
    ? window.cortexCore?.auth?.userProfile?.ctiProvider
    : "twilio";
  }

  ngOnInit(): void {
    const currentUrl = this.router.url;
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate([currentUrl]);
    });
    this.unsubscribeAll$ = new Subject();
    this.stateService
      .getValueOf('getGroup')
      .pipe(takeUntil(this.unsubscribeAll$))
      .subscribe(val => {
        if (val) {
          this.group = val;
        } else {
          this.getgroup();
        }
      });
  }
  getgroup(): void {
    const microApps = window.cortexCore.config.getConfig()['micro-apps'];
    this.router.events.pipe(takeUntil(this.unsubscribeAll$)).subscribe(event => {
      this.group = ['Other'];
      for (const key in microApps) {
        if (this.router.url.includes(`/${microApps[key].route}`) || microApps[key].route.includes(this.router.url.replace('/', ''))) {
          this.group.push(microApps[key].group);
        }
      }
      for (const cortexLearnLink of this.cortexLearnLinks) {
        if (this.router.url.includes(cortexLearnLink)) {
          this.group.push('Learn');
        }
      }
    });
  }

  checkCurrentLink(str: string): boolean {
    return this.router.url.includes(str);
  }
  get envUrl(): string {
    const cdUrl = window.cortexCore.config.getConfig()['conversationDesignerConfig']['endpointURL'];
    return `${cdUrl}`;
  }

  get virtualEnvUrl(): string {
    const vaUrl = window.cortexCore.config.getConfig()['virtualAssistantConfig']['endpointURL'];
    return `${vaUrl}`;
  }

  openDialer(): void {
    this.dialerEvent.emit(!this.dialer);
    this.dialer = !this.dialer;
  }

  sendTelemetry(): void {
    window.cortexCore?.telemetry.impression({
      type: IMPRESSIONEVENTS.VIEW,
      pageid: 'Cortex Shell',
      uri: ''
    });
  }

  OpenHelperDialog() {
    this.displayDialog = !this.displayDialog;
  }

  questionsExist(): boolean {
    return sessionStorage.getItem('questions') !== null;
  }
  ngOnDestroy(): void {
    this.unsubscribeAll$.next();
    this.unsubscribeAll$.complete();
  }
}
