<nb-layout>
  <nb-layout-column class="p-0">
    <ui-adaptors></ui-adaptors>
    <nav *ngIf="router.url !== '/'">
      <app-header></app-header>
    </nav>
    <app-sidebar *ngIf="router.url !== '/'" [dialer]="dialer" (dialerEvent)="openDialer($event)"></app-sidebar>
    <div id="content" [ngClass]="{ 'layout-column': router.url !== '/' }" class="main">
      <div>
        <router-outlet></router-outlet>
      </div>
      <div #appContainer id="app-container" [hidden]="router.url == '/'"></div>
    </div>
    <footer *ngIf="router.url !== '/'">
      <app-footer></app-footer>
    </footer>

    <!-- POPS UP ON SESSION EXPIRE -->
    <app-prompt-box
      *ngIf="sessionExpired"
      iconSource="assets/icons/interruptions.svg"
      promptTitle="Session Expired!"
      promptDesc="You need to relogin"
      cancelBtnText="Login again"
      [cancelBtnFunction]="logout"
    ></app-prompt-box>
  </nb-layout-column>
</nb-layout>
