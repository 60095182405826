import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-demo-videos',
  templateUrl: './demo-videos.component.html',
  styleUrls: ['./demo-videos.component.scss']
})
export class DemoVideosComponent implements OnInit{
  videoLink:string;
  videos = window.cortexCore.config.config?.features?.demoVideos?.videos;
  videoHeading = '';
  constructor(private router: Router){
  }

  ngOnInit(): void {
    const allDemoVideosAllowedUsers = window.cortexCore.config.config?.features?.demoVideos?.allowedAgents;
    const username = window.cortexCore.auth.userProfile.preferred_username;
    if((allDemoVideosAllowedUsers && !allDemoVideosAllowedUsers.includes(username)) || !allDemoVideosAllowedUsers){
      this.router.navigate([""]);
    }
  }

  playVideo(index){
    this.videoLink = this.videos[index].videoLink;
    this.videoHeading = this.videos[index].title;
  }

  close(){
    this.videoLink = '';
  }

}
